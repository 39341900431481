import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Blogs, {
  BLOGS_PER_PAGE,
  BLOG_INCREMENT,
  ICategorySummary,
} from "../components/blogs";
import Seo from "../components/seo";
import Subscribe from "../components/forms/subscribe";
import GTAButton from "../components/ui/gta-button";

const Blog = ({ data }) => {
  const {
    allContentfulBlogPost: {
      nodes: blogPosts,
      categories,
      totalBlogPosts,
      totalBlogPostsByCategory,
    },
  } = data;

  // place featured on top
  blogPosts.sort(function (x, y) {
    return x.featured === true ? -1 : y.featured === true ? 1 : 0;
  });

  // state for the list
  const [blogList, setBlogList] = useState([
    ...blogPosts.slice(0, BLOGS_PER_PAGE),
  ]);

  // state for trigger loadMore button
  const [loadMore, setLoadMore] = useState(false);

  // state of whether there is no more to load
  const [hasMore, setHasMore] = useState(blogPosts.length > BLOGS_PER_PAGE);

  // load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = blogList.length;
      const hasMoreToLoad = currentLength < blogPosts.length;
      const nextResult = hasMoreToLoad
        ? blogPosts.slice(currentLength, currentLength + BLOG_INCREMENT)
        : [];
      setBlogList([...blogList, ...nextResult]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore, blogList, blogPosts]);

  // check if there is more
  useEffect(() => {
    setHasMore(blogList.length < blogPosts.length);
  }, [blogList, blogPosts.length]);

  const categoriesSummary: ICategorySummary = {
    totalBlogPostsByCategory,
    totalBlogPosts,
  };

  return (
    <Layout>
      <Seo
        title="Blog"
        description="Des articles pertinents pour renouveller ton intelligence"
      />
      <section className="page blog-page">
        <Blogs
          blogPosts={blogList}
          categories={categories}
          title="Des articles pertinents pour renouveller ton intelligence"
          rootPath="/blog/"
          categoriesSummary={categoriesSummary}
        />
        {hasMore && (
          <div style={{ textAlign: "center", marginBottom: "3rem" }}>
            <span aria-hidden="true" onClick={handleLoadMore}>
              <GTAButton>Voir plus</GTAButton>
            </span>
          </div>
        )}
      </section>
      <Subscribe />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulBlogPost(sort: { fields: publishDate, order: DESC }) {
      nodes {
        id
        title
        slug
        description {
          description
        }
        body {
          body
        }
        publishDate(formatString: "DD.MM.YYYY", locale: "fr")
        categories
        heroImage {
          file {
            fileName
          }
          gatsbyImageData(
            layout: FULL_WIDTH
            width: 100
            height: 50
            resizingBehavior: FILL
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
        readTime
        featured
      }
      categories: distinct(field: categories)
      totalBlogPosts: totalCount
      totalBlogPostsByCategory: group(field: categories) {
        totalCount
        category: fieldValue
      }
    }
  }
`;

export default Blog;
